<template>
    <el-row>
        <el-col :id="id" :span="span" :style="{ height: `${height}px` }"></el-col>
    </el-row>
</template>

<script>
export default {
    name: "OneLineChart",
    data() {
        return {}
    },
    props: {
        id: {
            type: String,
            require: true
        },
        lineData: {
            type: Array,
            default: () => [820, 932, 901, 934, 1290, 1330, 1320]
        },
        xName: {
            type: Array,
            default: () => ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        height: {
            type: Number,
            default: 300
        },
        span: {
            type: Number,
            default: 24,
        },
        yLabel: {
            type: String,
            default: undefined
        }
    },
    methods: {
        drawLine() {
            let mChart = this.$echarts.init(document.getElementById(this.id))
            let temp = undefined
            if (this.yLabel === undefined) {
                temp = ""
            } else {
                temp = this.yLabel
            }
            let option = {
                // 添加 dataZoom 组件配置
                dataZoom: [{
                  type: 'slider', // 使用滑动条型 dataZoom 组件
                  start: 0, // 起始位置（百分比）
                  end: 100, // 结束位置（百分比）
                }],
                xAxis: {
                    type: 'category',
                    data: this.xName,
                    axisLabel: {
                        formatter: function (value) {
                            // 解析时间字符串
                            var time = value.split(':');
                            var hours = time[0];
                            var minutes = time[1];
                            var seconds = time[2];
                            // 调整分钟为 5 的倍数
                            var adjustedMinutes = Math.floor(minutes / 5) * 5;
                            // 格式化调整后的时间字符串
                            var formattedTime = hours + ':' + adjustedMinutes.toString().padStart(2, '0') + ':' + seconds;
                            return formattedTime;
                        }
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        formatter: '{value}' + temp
                    }
                },
                series: [
                    {
                        data: this.lineData,
                        type: 'line',
                        smooth: true
                    }
                ],
                tooltip: {
                    trigger: 'axis'
                },
            };
            mChart.setOption(option)
        }
    },
    created() {
        this.$nextTick(() => {
            this.drawLine()
        })
    }
}
</script>

<style></style>