<template>
    <div class="location">
        <button @click="getLocation">获取实时位置</button>
        <div>
            <p>纬度: {{ latitude }}</p>
            <p>经度: {{ longitude }}</p>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            latitude: null,
            longitude: null,
        };
    },
    methods: {
        getLocation() {
            if (navigator.geolocation) {
                navigator.geolocation.watchPosition(this.showPosition);
            } else {
                alert("Geolocation is not supported by this browser.");
            }
        },
        showPosition(position) {
            this.latitude = position.coords.latitude;
            this.longitude = position.coords.longitude;
        },
    },
};
</script>

<style scoped>
.location {
    text-align: center;
}
</style>
