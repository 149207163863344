<template>
    <div>
        <div ref="mapContainer" class="map-container"></div>
        <div class="location-details">{{ locationDetails }}</div>
    </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'

export default {
    name: 'AMap',
    data() {
        return {
            map: null,
            marker: null,
            districtSearch: null,
            locationDetails: ''
        }
    },
    created() {
        window._AMapSecurityConfig = {
            securityJsCode: '8d9e90c43a476d0004763d117e7ebc5f'
        }
    },
    mounted() {
        this.initMap()
    },
    methods: {
        async initMap() {
            await AMapLoader.load({
                key: '2021289da69523261dbf5925f2f5c711', // 替换为您的高德地图 API 密钥
                version: '2.0',
                plugins: ['AMap.Scale', 'AMap.ToolBar', 'AMap.DistrictSearch']
            })

            this.map = new AMap.Map(this.$refs.mapContainer, {
                center: [116.397428, 39.90923], // 北京市中心点
                zoom: 13
            })

            // 添加比例尺和工具栏
            this.map.addControl(new AMap.Scale())
            this.map.addControl(new AMap.ToolBar())

            // 配置行政区划查询插件
            this.districtSearch = new AMap.DistrictSearch({
                level: 'district', // 请求行政区划级别
                showbiz: true, // 是否显示商圈
                extensions: 'all' // 返回的结果需要包含边界坐标
            })

            // 创建一个标记点并添加到地图上
            this.marker = new AMap.Marker({
                position: this.map.getCenter(),
                draggable: true
            })

            this.marker.setMap(this.map)

            // 获取初始位置
            this.getCurrentPosition()
        },
        async getCurrentPosition() {
            if (navigator.geolocation) {
                navigator.geolocation.watchPosition(
                    (position) => {
                        const {latitude, longitude} = position.coords
                        this.updateMarkerPosition(longitude, latitude)
                        this.map.setZoomAndCenter(18, [longitude, latitude])
                        this.searchDistrict(longitude, latitude)
                    },
                    (error) => {
                        console.error('获取地理位置失败:', error)
                    },
                    {
                        enableHighAccuracy: true,
                        timeout: 5000,
                        maximumAge: 0
                    }
                )
            } else {
                console.error('浏览器不支持地理位置功能')
            }
        },
        updateMarkerPosition(lng, lat) {
            if (this.marker) {
                this.marker.setPosition(new AMap.LngLat(lng, lat))
            }
        },
        async searchDistrict(lng, lat) {
            const point = new AMap.LngLat(lng, lat)

            try {
                const result = await new Promise((resolve, reject) => {
                    this.districtSearch.search(point, (status, result) => {
                        if (status === 'complete') {
                            resolve(result)
                        } else {
                            reject(status)
                        }
                    })
                })

                const district = result.districtList[0]

                // // 更新 locationDetails 数据属性
                // this.locationDetails = `
                //       省份：${district.province()}
                //       城市：${district.city}
                //       区/县：${district.name}
                //       地址：${district.center}
                //       经度：${lng.toFixed(6)}
                //       纬度：${lat.toFixed(6)}
                //     `
            } catch (error) {
                console.error('获取街区信息失败:', error)
            }
        }

    }
}
</script>

<style>
.map-container {
    width: 100%;
    height: 50vh;
}

.location-details {
    position: absolute;
    bottom: 20px;
    left: 20px;
    padding: 10px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    max-width: 300px;
    font-size: 14px;
}

</style>
