<template>
    <div>
        <div ref="mapContainer" class="map-container"></div>
    </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'

export default {
    name: 'MapWithPath',
    data() {
        return {
            map: null,
        }
    },
    props: {
        // 模拟经纬度坐标序列
        pathCoordinates: {
            type: Array,
            default: ()=>[
                [116.405289, 39.904987],
                [116.406979, 39.913291],
                [116.415789, 39.913032],
                [116.415536, 39.906951]
            ]
        },
    },
    mounted() {
        this.initMap()
    },
    created() {
        window._AMapSecurityConfig = {
            securityJsCode: '8d9e90c43a476d0004763d117e7ebc5f'
        }
    },
    methods: {
        async initMap() {
            await AMapLoader.load({
                key: '2021289da69523261dbf5925f2f5c711', // 替换为您的高德地图 API 密钥
                version: '2.0',
                plugins: ['AMap.Scale', 'AMap.ToolBar']
            })

            this.map = new AMap.Map(this.$refs.mapContainer, {
                center: [116.397428, 39.90923], // 北京市中心点
                zoom: 13
            })

            // 添加比例尺和工具栏
            this.map.addControl(new AMap.Scale())
            this.map.addControl(new AMap.ToolBar())
            // 绘制运动轨迹
            this.drawPath()
        },
        drawPath() {


            // 创建折线实例并设置属性
            const polyline = new AMap.Polyline({
                path: this.pathCoordinates,
                strokeColor: '#FF0000', // 线颜色
                strokeOpacity: 1, // 线透明度
                strokeWeight: 3, // 线宽
                strokeStyle: 'solid', // 线样式
                lineJoin: 'round' // 折线拐点连接处样式
            })

            // 将折线添加到地图上
            polyline.setMap(this.map)

            // 自动调整地图视野以适应折线
            this.map.setFitView(polyline)
        }
    }
}
</script>

<style>
.map-container {
    width: 100%;
    height: 50vh;
}
</style>
